import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { get } from "../../utils/api";
import Banner from "../../components/Banner";
import { getStoragePath } from "../../utils/api";
import './index.scss'
import Title from "../../components/Title";
import Button from "../../components/Button";

const Game = () => {
    let { slug } = useParams();
    const [game, setGame] = useState<any>(null)
    const [loading, setLoading] = useState(true)
    const [showingGame, setShowingGame] = useState(false)
    const iframeRef = useRef(null);

    const loadGame = async () => {
        var gameResult = await get(`/games/by-slug/${slug}`)
        setGame(gameResult)
        setLoading(false)
    }

    useEffect(() => {
        loadGame()
    }, [])

    function goFullScreen() {
        // const iframe = document.getElementById('gameFrame');
        if (!showingGame) {
            setShowingGame(true);
        }
        setTimeout(() => {
            if (iframeRef) {
                if ((iframeRef.current! as any).requestFullscreen) {
                    (iframeRef.current! as any).requestFullscreen();
                //   } else if (iframe.mozRequestFullScreen) { // Firefox
                //     iframe.mozRequestFullScreen();
                //   } else if (iframe.webkitRequestFullscreen) { // Chrome, Safari, Opera
                //     iframe.webkitRequestFullscreen();
                //   } else if (iframe.msRequestFullscreen) { // IE/Edge
                //     iframe.msRequestFullscreen();
                //   }
                }
            }
        }, 0)
      }

    if (loading) {
        return <>Loading...</>
    }

    return <div className="GamePage__Container">
        <Banner img={getStoragePath(game!.cover)}/>
        <div className="GamePage__Content">
            <div className="GamePage__Column">
                <Title>{game!.title}</Title>
                <div className="GamePage__Description">
                    {game!.description_long}
                </div>
            </div>
            <div className="GamePage__Column">
                <div
                    className="GamePage__GameScreen"
                    style={{
                        backgroundImage: `url(${getStoragePath(game!.game_screen)})`
                    }}>
                        <div className="GamePage__GameOverlay"/>
                        
                         {showingGame ? <iframe
                            className="GamePage__Game"
                            id="gameFrame"
                            ref={iframeRef}
                            src={"http://localhost:3000/storage/office-quest/index.html"} 
                            width="100%" 
                            height="100%" 
                            style={{ border: 'none' }}
                            title="External Content"
                        ></iframe> : <div className="GamePage__GameOverlay"/>}
                        <Button
                            className="GamePage__Play"
                            onClick={goFullScreen}
                        >{showingGame ? "RIPRENDI" : "GIOCA"}</Button>
                    
                </div>
            </div>
        </div>
    </div>
}

export default Game