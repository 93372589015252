import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/AuthProvider";
import { get } from "../utils/api";
import Title from "../components/Title";
import Banner from "../components/Banner";
import banner from "../images/ND_Playroom.png"
import GameItem, { GameItemList } from "../components/GameItem";


const Home = () => {
  const auth = useAuth();
  const [games, setGames] = useState<any[]>([])
  const [loading, setLoading] = useState(true)

  const loadGames = async () => {
    var gamesResult = await get("/games")
    setLoading(false)
    setGames(gamesResult)
  }

  useEffect(() => {
    loadGames()
  }, [])

  return (<div className="container">
        {!auth.username && <Banner img={banner}/>}
        {auth.username ? 
          <Title>Benvenuto {auth.username}, ecco tuoi giochi:</Title>
          :
          <Title>I nostri giochi:</Title>
        }
       {loading ?
       <>Caricamento giochi...</> :
       <GameItemList>
          {games.map(game => 
            <GameItem
              thumbnail={game.thumbnail}
              title={game.title}
              description={game.description}
              slug={game.slug}
              />
          )}
       </GameItemList>}
    </div>
  );
};

export default Home;