import React from "react";
import './index.scss'
import logo from "../../images/Logo.png"
import { useAuth } from "../../hooks/AuthProvider";
import { useNavigate } from "react-router-dom";

const MenuOption = ({
    children,
    onClick
}: {
    children: any,
    onClick: () => any
}) => {
    return <button
        className="Header__MenuOption"
        onClick={onClick}
        >
        {children}
    </button>
}

const HeaderMenu = () => {
    const auth = useAuth();
    const navigate = useNavigate()
    return <>
        {auth.username ? 
            <>
                {/* <h1>Welcome {auth.username}!</h1> */}
                <MenuOption
                    onClick={auth.logOut!}
                >Logout</MenuOption>
            </>
            :
            <MenuOption
                onClick={() => {
                    navigate("/login")
                }}
            >Login</MenuOption>
            }
    </>
}

const Header = () => {
    const navigate = useNavigate()
    return <div className="Header__Container">
        <div className="Header__Content">
            <img
                className="Header__Logo"
                src={logo}
                onClick={() => {navigate("/")}}
            />
            <div className="Header__Spacer"/>
            <div className="Header__Menu">
                <HeaderMenu/>
            </div>
        </div>
    </div>
}

export default Header