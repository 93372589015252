import React from "react";
import './index.scss';
import { getStoragePath } from "../../utils/api";
import Button from "../Button";
import { useNavigate } from "react-router-dom";

const GameItemList = ({children} : {children: any}) => {
    return <div className="GameItemList">{children}</div>
}

const GameItem = ({
    title,
    description,
    thumbnail,
    slug
}:{
    title: string,
    description: string,
    thumbnail: string,
    slug: string
}) => {
    const navigate = useNavigate()
    return <div className="GameItem__Container">
            <div className="GameItem__Thumbnail"
            style={{
                backgroundImage: `url(${getStoragePath(thumbnail)})`
            }}
            />
            <div className="GameItem__Title">
                {title}
            </div>
            <div className="GameItem__Description">
                {description}
            </div>
            <div className="GameItem__Actions">
                <Button
                    onClick={() => {
                        navigate(`/game/${slug}`)
                    }}
                >GIOCA</Button>
            </div>
        </div>
}

export default GameItem

export {
    GameItemList
}