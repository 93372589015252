import { useContext, createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { post } from "../utils/api";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext<{
  username?: string | null,
  games_owned?: Array<string>
  loginAction?: (data: any, intended: string) => any,
  logOut?: () => any
}>({});

const AuthProvider = ({ children } : {children: any}) => {
    const [username, setUsername] = useState<any>(null);
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();

    useEffect(() => {
      verifyUser()
    }, [])

    const verifyUser = async () => {
      const res = await post("/auth/me")
      if (res.username) {
        setUsername(res.username)
      } else {
        setUsername(null)
      }
      setLoading(false)
    }

    const loginAction = async (data: any, intended: string) => {
      try {
        const res = await post("/auth/login", data)
        if (res.username) {
          setUsername(res.username)
          navigate(intended);
          return;
        }
        throw new Error(res.message);
      } catch (err) {
        console.error(err);
      }
    };
  
    const logOut = async () => {
      try {
        const res = await post("/auth/logout")
        setUsername(null)
        navigate("/");
        return;
      } catch (err) {
        console.error(err);
      }
    };

    if (loading) {
      return <>Loading...</>
    }
  
    return (
      <AuthContext.Provider value={{username, loginAction, logOut }}>
        {children}
      </AuthContext.Provider>
    );
  
  };

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
