import React from 'react';
import './scss/global.scss'
import './App.css';
import Login from './pages/Login';

import {
  Routes,
  BrowserRouter as Router,
  Route
} from "react-router-dom";
import AuthProvider from './hooks/AuthProvider';
import PrivateRoute from './components/PrivateRoute';
import Home from './pages/Home';
import Game from './pages/Game';
import Layout from './components/Layout';

function App() {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<Layout/>}>
              <Route path="/" element={<Home/>} />
              <Route element={<PrivateRoute/>}>
                <Route path="/private" element={<>This is private</>}/>
                <Route path="/game/:slug" element={<Game/>}/>
              </Route>
              <Route path="/*" element={<>404</>} />
            </Route>
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
