import React from "react";
import './index.scss';

const Banner = ({img}:{img: any}) => {
    return <div className="Banner"
        style={{
            backgroundImage: `url(${img})`
        }}
    />
}

export default Banner