import React from "react";
import './index.scss';
import Header from "../Header";
import { Outlet } from "react-router-dom";
import Footer from "../Footer";

const Layout = () => {
    return <div className="Layout__Container">
        <Header/>
        <div className="Layout__Content">
            <Outlet/>
        </div>
        <Footer/>
    </div>
}

export default Layout