
import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/AuthProvider";

const PrivateRoute = () => {
  const user = useAuth();
  const location = useLocation();

  if (!user.username) return <Navigate to={`/login?intended=${encodeURIComponent(location.pathname)}`} />;
  return <Outlet />;
};

export default PrivateRoute;