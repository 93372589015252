import * as React from "react"
import './index.scss'

const Button = ({
    children,
    className,
    onClick,
    disabled,
    style={},
    submit,
    reverse=false
}: {
    children: any,
    className?: string,
    onClick?: () => void,
    disabled?: boolean,
    style?: object,
    submit?: boolean,
    reverse?: boolean
}) => {
    return <button
        className={"Button__Container " + className
            + (disabled ? ' Button__Container--disabled ' : '')
            + (reverse ? ' Button__Container--reverse ' : '')}
        onClick={onClick}
        style={style}
        type={submit ? 'submit': 'button'}
    >
         <svg
        className={reverse? "reverse-fill":"primary-fill"}
        width="300"
        height="80"
        viewBox="0 0 96.395065 15.643647"
        version="1.1"
        id="svg1"
        xmlns="http://www.w3.org/2000/svg">
            <defs id="defs1" />
            <g
                id="layer1"
                transform="translate(-59.282708,-70.984095)">
                <path
                    id="rect1"
                    d="m 59.282708,70.984095 h 96.395062 v 10.08343 l -5.99009,5.560217 H 59.282708 Z" />
            </g>
        </svg>
        <div
            className="Button__Content"
        >
            <div className={"Button__Text "
                + (reverse ? 'primary-color ' : '')
            }>
                {children}
            </div>
        <svg
            className={reverse ? 'primary-fill' : 'background-fill'}
            version="1.1"
            id="svg39590"
            width="20"
            height="20"
            viewBox="0 0 32 31.195312"
            xmlns="http://www.w3.org/2000/svg">
            <defs
                id="defs39594" />
            <g
                id="g39596"
                transform="translate(0,-0.47070313)">
                <path
                id="rect39717"
                d="M 0,0.47070312 V 7.3339844 H 19.171875 L 5.640625,20.865234 10.933594,26.160156 25.113281,11.980469 V 31.666016 H 32 V 7.3339844 0.47070312 h -6.886719 z" />
            </g>
            </svg>

        </div>
    </button>
}

export default Button