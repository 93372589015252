import {useState} from "react";
import { useAuth } from "../../hooks/AuthProvider";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import './index.scss'
import Footer from "../../components/Footer";
import logo from "../../images/Logo.png"
import Button from "../../components/Button";
const Login = () => {
    const [input, setInput] = useState({
        username: "",
        password: ""
    })

    const location = useLocation();
    const navigate = useNavigate();

    const auth = useAuth();
    if (auth.username) return <Navigate to="/" />;

    const handleSubmitEvent = (e: any) => {
        e.preventDefault();
        const params = new URLSearchParams(location.search);
        const redirectUrl = params.get("intended") || "/"; // Default to home if no redirect is found
        if (input.username !== "" && input.password !== "") {
            auth.loginAction!(input, redirectUrl);
            return
        }
        alert("please provide a valid input")
    }

    const handleInput = (e: any) => {
        const { name, value } = e.target;
        setInput((prev) => ({
          ...prev,
          [name]: value,
        }));
    };

    return (<div className="LoginPage__Container">
        <div className="LoginPage__Content">
            <div className="LoginPage__Card">
                <img
                    className="LoginPage__Logo"
                    src={logo}
                    onClick={() => {navigate("/")}}
                />
                <form className="LoginPage__Form" onSubmit={handleSubmitEvent}>
                    <input
                        className="LoginPage__Input"
                        type="email"
                        id="user-email"
                        name="username"
                        placeholder="example@email.com"
                        aria-describedby="user-email"
                        aria-invalid="false"
                        onChange={handleInput}
                    />
                    <input
                    className="LoginPage__Input"
                    type="password"
                    id="password"
                    name="password"
                    aria-describedby="user-password"
                    aria-invalid="false"
                    onChange={handleInput}
                    />
                    <Button submit reverse>ACCEDI</Button>
                    <div className="LoginPage__GuestMessage">
                        Oppure <a
                        href="#"
                            onClick={() => {
                                navigate("/")
                            }}
                        >torna alla home</a> per navigare come ospite.
                    </div>
                </form>
            </div>
        </div>
        <Footer/>
        </div>
    )
}

export default Login;